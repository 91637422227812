const Aboutus = () => {
    return (
        <div style={{ padding: '50px 50px 0'}}>
            <div className="heading" style={{ textAlign: 'center'}}>About</div>
            <h3>We are,</h3>
            <p>We have the expertise to transform your business using a digital perspective. Our foundation in software engineering, paired with our strategic business consulting, innovative design thinking, and the ability to bridge the physical and digital realms, delivers tangible business benefits to our clients through innovation centered around human needs and experiences.</p>
            <h3>Our Vision</h3>
            <p>To be the foremost consulting partner, known for our unwavering commitment to client success, unparalleled expertise, and innovative solutions. We aspire to drive transformative change for organizations worldwide, fostering sustainable growth, and making a lasting impact on businesses, communities, and the global landscape.</p>
            <h3>Our Mission</h3>
            <p>Our mission is to empower organizations with expert guidance and customized solutions that drive excellence. We are dedicated to delivering strategic insights, fostering innovation, and facilitating growth. Through collaboration, integrity, and a client-centric approach, we aim to be the trusted catalyst for positive change and success in the ever-evolving business landscape.</p>
        </div>
    )
}

export default Aboutus;