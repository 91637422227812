import './App.css';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import Routers from './components/routers/routers';

function App() {
  return (
    <div className="App">
      <Header />
      <Routers />
      <Footer/>
    </div>
  );
}

export default App;
