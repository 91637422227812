import React from 'react';

const Footer = () => {
    return (
        <div style={{
            padding: 20,
            background: '#005776',
            color: 'white'}}>
            <div style={{ marginBottom: 20, textAlign: 'center' }}>We are always ready to hear your concern or feedback, write to <b>hr@vianitservices.com</b></div>
            <div style={{ marginBottom: 20, textAlign: 'center' }}>©2023 VianItServices. All rights reserved </div>
            <div>Powered by <b>InfoInTube Private Ltd</b> © 2022 - 2023  <a href='https://www.infointube.com/' rel="noreferrer" style={{ color: 'white'}} target="_blank">https://www.infointube.com/</a> </div>
        </div>
    )
}

export default Footer;