import {
    Routes,
    Route
  } from "react-router-dom";

  import HomePage from '../../pages/home/home';
  import AboutusPage from '../../pages/aboutus/aboutus';
  import ServicesPage from '../../pages/services/services';
  import ContactusPage from '../../pages/contactus/contactus';


const Routers = () => {
    return (
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/about' element={<AboutusPage />} />
                <Route path='/services' element={<ServicesPage />} />
                <Route path='/contact' element={<ContactusPage />} />
            </Routes>
    )
}

export default Routers;