import React, { useState } from 'react';
import './contactus.scss';

const Contactus = () => {
    const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');


  const handleOnChange = (value, key) => {
    if (key === 'name') {
      setName(value);
    } else if (key === 'mobile') {
      setMobile(value);
    } else if (key === 'email') {
      setEmail(value);
    } else if (key === 'message') {
      setMessage(value);
    }
 };

 const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const validateEmail = (email) => {
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      return true;
    } else {
      return false
    }
  }

  const submitForm = (e) => {
    e.preventDefault();
    const formErrors = {};
    if(!name) {
      formErrors.name = true;
    }
    if(!email) {
      formErrors.email = true;
    } else {
      formErrors.email = !validateEmail(email)
    }
    if(mobile && (mobile.length !== 10 || isNaN(mobile))) {
      formErrors.mobile = true;
    }
    if(!message) {
      formErrors.message = true;
    }
    for(let i in formErrors) {
      if(formErrors[i]) {
        return;
      }
    }
     if(!formErrors.name && !formErrors.mobile && !formErrors.email && !formErrors.message ) {
       const contactData = {
         name,
         mobile,
         email,
         message
       };
       fetch("/", {
         method: "POST",
         headers: {"Content-Type": "application/x-www-form-urlencoded"},
         body: encode({"form-name": "contact", ...contactData})
       })
         .then(() => {
          alert('Thank you for contacting us, \n Will revert you shortly!');
           setName('');
           setMobile('');
           setEmail('');
           setMessage('');
         })
         .catch(error => alert(error));
 
     }
  };


  return (
    <div className='contact-section'>

        <form className='contact-form' name="contact"  data-netlify="true"  onSubmit={submitForm}>
            <div className='heading'>Send Us Message</div>
            <div>
                <input type="text" id="name" value={name} placeholder="Name" required onChange={(e) => handleOnChange(e.target.value, 'name')} />
            </div>
            <div>
                <input type="text" id="mobile" value={mobile} placeholder="Mobile"
                    onChange={(e) => handleOnChange(e.target.value, 'mobile')} required />
            </div>
            <div>
                <input type="email" id="email" value={email} placeholder="Email"
                    onChange={(e) => handleOnChange(e.target.value, 'email')} required />
            </div>
            <div>
                <input id="message" value={message} placeholder="Message"
                    onChange={(e) => handleOnChange(e.target.value, 'message')} required />
            </div>
            <button type="submit">Submit</button>
        </form>
        <div className='address-section'>
            <div className='heading'>
                Drop In Our office
            </div>
            <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
                <span>
                 <i class="bi bi-geo-alt-fill" style={{ fontSize: 22 }} />
                </span>
                <div><div>MIDRAND,</div> <div>GP 94126,</div> <div>SA 0105937747</div></div>
            </div>
            <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
                <span><i class="bi bi-telephone-inbound-fill" style={{ fontSize: 20 }} /></span>
                <div>+ 27105937747</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span><i class="bi bi-envelope-fill" style={{ fontSize: 20 }} /></span>
                <div>hr@vianitservices.com</div>
            </div>
        </div>
    </div>
  );
};


export default Contactus;