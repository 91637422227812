import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './header.scss';
import logo from '../../images/logo.png';

const links = [
    {
        url: '/',
        text: 'Home'
    },
    {
        url: '/about',
        text: 'About'
    },
    {
        url: '/services',
        text: 'Services'
    }
]

const Header = () => {
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigateToHome = () => {
        navigate('/');
    }
    return (
        <>
        <div className='header-top d-none d-lg-flex' style={{ color: 'white' }}>
            <div className='left-section'>
                <Link to='/'>
                    <i class="bi bi-house-fill" style={{ color: 'white', marginRight: 5 }} />
                    <span style={{ color: 'white' }}>Home</span></Link>
            </div>
            <div className='right-section'>
                <div>
                <i class="bi bi-telephone-inbound-fill" style={{ color: 'white', marginRight: 5 }} />
                    <span>+27105937747</span>
                </div>
                <div>
                <i class="bi bi-envelope-fill" style={{ color: 'white', marginRight: 5 }} />
                    <span>hr@vianitservices.com</span>
                </div>
            </div>
        </div>
        <div className='mobile-header'>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Body>
                <div className='logo-section text-center'>
                    <img src={logo} alt="Logo" onClick={navigateToHome} />
                </div>
                {
                    links.map((link, index) => {
                        return (
                            <div className='link' key={index} onClick={handleClose}>
                                <Link to={`${link.url}`}>{link.text}</Link>
                            </div>
                        )
                    })
                }
                <button className='contact-button' onClick={handleClose}>
                    <Link to={`/contact`}>Contact</Link>
                </button>
                </Offcanvas.Body>
            </Offcanvas>
      </div>
        <div className='header'>
            <div className='logo-section'>
                <img src={logo} alt="Logo" onClick={navigateToHome} />
            </div>
            <span className='d-sm-block d-md-block d-lg-none'>
                <i 
                    class="bi bi-list" 
                    style={{ fontSize: 60, color: '#005776' }} 
                    onClick={handleShow}
                />
            </span>
            <div className='links-section d-none d-lg-flex'>
                {
                    links.map((link, index) => {
                        return (
                            <div className='link' key={index}>
                                <Link to={`${link.url}`}>{link.text}</Link>
                            </div>
                        )
                    })
                }
                <button className='contact-button'>
                    <Link to={`/contact`}>Contact</Link>
                </button>
            </div>
        </div>
        </>
    )
}

export default Header;