import './services.scss';
import service1 from '../../images/service-1.png';
import service2 from '../../images/service-2.png';
import service3 from '../../images/service-3.png';
import service4 from '../../images/service-4.png';
import service5 from '../../images/service-5.png';
import service6 from '../../images/service-6.png';
import service7 from '../../images/service-7.png';


const Services = () => {
    return (
        <div className="services-section" >
            <div className="heading" style={{ textAlign: 'center'}}>Our Services</div>
            <div className='service'>
                <div className='service-text'>
                    <div className='heading'>Internet Security</div>
                    <div className='text'>
                        Internet security is a crucial aspect of digital life and is essential for individuals, businesses, and organizations that rely on the internet for communication, commerce, and information exchange. Without proper internet security measures in place, users may be at risk of identity theft, financial fraud, and other malicious activities that can cause significant harm. Internet security involves using a range of tools, techniques, and technologies to safeguard digital data and prevent cyber criminals from accessing sensitive information or disrupting online operations.
                    </div>
                    <div className='text'>
                        the Diner comes with a glossy table top that is sure to add an extra oomph to your dinner spread. Paired with the Diner chairs and the Diner bench, it is easy on the eye and easy to fall in love with!
                    </div>

                </div>
                <div className='service-image'>
                    <img src={service1} alt="" />
                </div>
            </div>
            <div className='service'>
                
                <div className='service-text'>
                    <div className='heading'>Digital Technologies</div>
                    <div className='text'>
                        Digital technologies have transformed the way we live, work, and communicate, and they continue to evolve at a rapid pace, driving innovation and growth across many industries. Digital technologies is often powered by computer systems and software, and they are designed to process, store, transmit, and display information in digital form. Digital technologies can help organizations to become more agile, efficient, and customer-focused, enabling them to thrive in an increasingly competitive and digital world.
                    </div>
                    <div className='text'>
                    </div>

                </div>
                <div className='service-image'>
                    <img src={service2} alt="" />
                </div>
                
            </div>
            <div className='service'>
                <div className='service-text'>
                    <div className='heading'>Application Development</div>
                    <div className='text'>
                        Applications can be developed for various purposes, such as business productivity, e-commerce, social networking, and entertainment. Successful application development requires a thorough understanding of the user's needs, as well as the technical skills to create an application that is both functional and easy to use. Application development can involve a wide range of technologies, including programming languages like Java, Python, and JavaScript, and software development frameworks such as React, Angular, and Node. Application development can also be done for various platforms, such as desktop computers, mobile devices, and the web. Application development typically involves a team of developers, designers, and project managers working together to create an application that meets the needs of its users.
                    </div>
                    <div className='text'>

                    </div>

                </div>
                <div className='service-image'>
                    <img src={service3} alt="" />
                </div>
            </div>
            <div className='service'>
            
                <div className='service-text'>
                    <div className='heading'>Testing Analyst</div>
                    <div className='text'>
                        The testing analyst plays a crucial role in the software development life cycle by identifying and reporting defects, errors, and bugs that can impact the functionality and usability of the application. individuals should have a strong understanding of software testing methodologies, testing tools, and technologies, as well as the ability to analyze and interpret technical data. They should also have excellent communication and problem-solving skills, attention to detail, and the ability to work effectively in a team environment.
                    </div>
                    <div className='text'>

                    </div>

                </div>
                <div className='service-image'>
                    <img src={service4} alt="" />
                </div>
            </div>
            <div className='service'>
                <div className='service-text'>
                    <div className='heading'>Business Intelligence</div>
                    <div className='text'>
                        Business intelligence involves collecting data from various sources, such as databases, spreadsheets, and cloud-based services, and transforming it into a format that can be easily analyzed using BI tools such as dashboards, scorecards, and data visualization software. BI can help organizations to identify key performance indicators (KPIs) and monitor them in real-time, enabling them to make informed decisions and take action based on insights gleaned from the data which leads to improve performance, reduce costs, and identify new opportunities. Implementing a successful BI strategy requires careful planning, execution, and ongoing management to ensure that the data is accurate, relevant, and timely, and that the insights gained are actionable and valuable to the organization.
                    </div>
                    <div className='text'>

                    </div>

                </div>
                <div className='service-image'>
                    <img src={service5} alt="" />
                </div>
            </div>
            <div className='service'>
                <div className='service-text'>
                    <div className='heading'>Artifical Intelligence</div>
                    <div className='text'>
                        AI, or Artificial Intelligence, refers to the simulation of human intelligence in machines that are programmed to perform tasks that typically require human intelligence, such as learning, problem-solving, perception, and decision-making. AI is a broad field that encompasses a wide range of technologies, from machine learning and deep learning to natural language processing and computer vision. AI has the potential to revolutionize the way we live and work, enabling new levels of automation, innovation, and efficiency. Below are the few areas AI applications include: Chatbots and virtual assistants, Recommendation engines, Image and speech recognition and Autonomous vehicles.
                    </div>
                    <div className='text'>

                    </div>

                </div>
                <div className='service-image'>
                    <img src={service6} alt="" />
                </div>
            </div>
            <div className='service'>
                <div className='service-text'>
                    <div className='heading'>SAP</div>
                    <div className='text'>
                        SAP is a popular enterprise resource planning (ERP) software system that is widely used by businesses to manage their operations, including finance, procurement, logistics, and human resources. SAP programming involves developing software applications that extend the functionality of the SAP system, enabling businesses to meet their specific needs and requirements. SAP programming can be done using various programming languages, such as ABAP (Advanced Business Application Programming), and JavaScript, depending on the specific application and requirements. SAP programming requires a deep understanding of the SAP system, including its modules, data structures, and APIs, as well as a strong knowledge of programming languages and software development tools.
                    </div>
                    <div className='text'>

                    </div>

                </div>
                <div className='service-image'>
                    <img src={service7} alt="" />
                </div>
            </div>

        </div>
    )
}

export default Services;