import { useNavigate } from "react-router-dom";
import './home.scss';
import HomeImage from '../../images/home-image.jpg'
import aboutIntroImg from '../../images/about-intro.jpg'

import service1 from '../../images/service-1.png';
import service2 from '../../images/service-2.png';
import service3 from '../../images/service-3.png';
import service4 from '../../images/service-4.png';
import service5 from '../../images/service-5.png';
import service6 from '../../images/service-6.png';
import service7 from '../../images/service-7.png';

const Home = () => {
    const navigate = useNavigate(); 

    const changeToAboutPage = () => {
        navigate('/about');
    }

    return (
        <div className="home-section">
            <div className='home-banner'>
                <img src={HomeImage} alt="Home" />
                <div className='quotes'>
                    <h1>Connect to onboard on best</h1>
                    <h2>Working Environment</h2>
                </div>
            </div>
            <div className='about-intro'>
                <div className='intro-text'>
                    <div className='heading'>Welcome, We are</div>
                    <div className='text'>
                        We have the expertise to transform your business using a digital perspective. Our foundation in software engineering, paired with our strategic business consulting, innovative design thinking, and the ability to bridge the physical and digital realms, delivers tangible business benefits to our clients through innovation centered around human needs and experiences.
                    </div>
                    <button onClick={changeToAboutPage}>
                        <span>Learn More</span>
                        <i class="bi bi-arrow-up-right" />
                    </button>

                </div>
                <div className='intro-image'>
                    <img src={aboutIntroImg} alt="" />
                </div>
            </div>
            <div className="services-section">
                <div className="title-section">
                    <span className="hr" />
                     <span>OUR &nbsp;</span>  
                     <span style={{color: '#005776', fontWeight: 'bold'}}>SERVICES</span>   
                     <span className="hr" />

                </div>
                <div className="services-tiles">
                    <div>
                        <img src={service1} alt="service" />
                    </div>
                    <div>
                        <img src={service2} alt="service" />
                    </div>
                    <div>
                        <img src={service3} alt="service" />
                    </div>
                    <div>
                        <img src={service4} alt="service" />
                    </div>
                    <div>
                        <img src={service5} alt="service" />
                    </div>
                    <div>
                        <img src={service6} alt="service" />
                    </div>
                    <div>
                        <img src={service7} alt="service" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;